<template>
  <div class="Qualification">
    <!-- 资质申请信息 -->
    <div class="applyType-box scorr-roll">
      <div class="applyType">
        <!-- 商业信息 -->
        <div class="dis">
          <div class="applyType-name1">单位资质信息</div>
          <div
            class="applyType-name"
            @click="todown"
            v-if="getQualifyDetailMess.length > 0&&allJurisdiction.usersCenterList151"
          >
            <i class="iconfont SF-huiyuanzhongxin1"></i>
            下载资质
          </div>
        </div>

        <div class="box-message" v-if="getQualifyDetailMess.length > 0">
          <div
            class="qua-message-box"
            v-for="(item, index) in getQualifyDetailMess"
            :key="index"
          >
            <!-- {{item.period_validity}}--213 -->
            <!-- <div class="qua-message-title">
              <el-form ref="form" label-width="100px">
                <el-form-item :label="item.name" :title="item.name">
                  
                  <div class="sch-1 sch-2">
                    <div class="sch-title">到期时间:</div>
                    <el-date-picker
                      v-model="item.period_validity"
                      type="date"
                      value-format="yyyy-MM-dd"
                    >
                    </el-date-picker>
                  </div>
                </el-form-item>
              </el-form>
            </div> -->
            <div class="Qualification-img">
              <el-form ref="form" label-width="100px">
                <el-form-item>
                  <div class="zw-vox-img">
                    <div class="describe" :title="item.item_name">
                      <div>
                        {{ item.item_name }}
                      </div>

                      <div>
                        有效期至：<span
                          v-if="
                            item.effective_end_time == '9999-12-31 00:00:00'
                          "
                          >长期</span
                        ><span v-else>{{ item.effective_end_time }}</span>
                      </div>
                    </div>
                    <div class="marbox">
                      <div
                        class="img-box"
                        v-for="(v, i) in item.picture"
                        :key="i"
                      >
                        <div class="demo-image__preview upImg">
                          <el-image :src="v" :preview-src-list="[v]">
                          </el-image>
                        </div>
                      </div>
                    </div>
                  </div>
                </el-form-item>
              </el-form>
            </div>
          </div>
        </div>
        <div class="box-message-1" v-else>暂未上传相关资质</div>
      </div>
    </div>
    <div class="operating-btn">
      <div class="btn-1" @click="cancelUser">关闭</div>
      <!-- <div class="btn-1 btn-2" @click="savequalify">保存</div> -->
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const userCenterList = createNamespacedHelpers("userCenterList");
const Audit = createNamespacedHelpers("Audit"); //vuex公共库
const commonIndex = createNamespacedHelpers("commonIndex"); //vuex公共库
export default {
  data() {
    return {
      auditOp: [
        {
          type: 4,
          name: "未过审",
        },
        {
          type: 3,
          name: "已过审",
        },
        {
          type: 2,
          name: "待审核",
        },
      ],
      getQualifyDetailMess: {},
    };
  },
  computed: {
    ...userCenterList.mapState(["userQualifyDetail"]),
    ...commonIndex.mapState(['allJurisdiction']),
  },
  created() {
    this.dispose();
  },
  methods: {
    ...Audit.mapActions(["getmemberAuditqualifyDown"]),
    ...userCenterList.mapActions(["getQualifyDetail"]),
    // 保存资质编辑
    savequalify() {},
    // 处理资质格式
    async dispose() {
      let data = await this.getQualifyDetail({ user_id: this.$route.query.id });
      data.forEach((v) => {
        if (v.detail) {
          this.$set(v, "picture", []);
          v.detail.forEach((v1) => {
            if (v1.item_type == 3) {
              v.picture.push(v1.img_url);
            }
          });
        }
      });
      this.getQualifyDetailMess = data;
    },
    // 返回用户列表
    cancelUser() {
      this.$router.push({ path: "/usersCenterList" });
    },
    async todown() {
      let data = await this.getmemberAuditqualifyDown({
        user_id: this.$route.query.id,
      });
      if (data.code == 200) {
        window.open(data.data, "");
        this.$message({
          message: data.message,
          type: "success",
        });
      } else {
        this.$message({
          message: data.message,
          type: "warning",
        });
      }
    },
  },
};
</script>
<style lang='scss' scoped>
.el-range-separator {
  padding: 0px 0px !important;
  width: 20% !important;
}
.el-input__inner {
  border-radius: 4px !important;
}
.el-date-picker {
  width: 300px !important;
}
.el-form-item__label {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
}
</style>
<style lang="scss" scoped>
.Qualification {
  margin-top: 20px;
  width: 100%;
  .quaName {
    font-size: 14px;
    font-weight: bold;
    color: #383b46;
  }
  .quaType {
    font-weight: bold;
    color: #3d70ff;
    font-size: 14px;
  }
  .applyType-box {
    padding-top: 10px;
    width: 100%;
    height: 58vh;
    .applyType {
      width: 100%;
      min-width: 800px;
      // padding: 0px 100px;
      .applyType-name1 {
        width: 150px;
        height: 40px;
        // background: #3d70ff;
        text-align: center;
        line-height: 40px;
        border-radius: 5px 5px 5px 5px;
        opacity: 1;
        font-size: 16px;
        font-weight: bold;
        color: #333333;
      }
      .applyType-name {
        opacity: 1;
        font-size: 12px;
        color: #06b7ae;
      }
      .applyType-name1::before {
        width: 4px;
        height: 16px;
        background-color: #06b7ae;
        border-radius: 10px;
        display: inline-block;
        content: "";
        margin-right: 10px;
        margin-bottom: -2px;
      }
      .box-message {
        display: flex;
        flex-wrap: wrap;
      }
      .box-message-1 {
        width: 100%;
        height: 50px;
        line-height: 50px;
      }
      .qua-message-box {
        margin-top: 20px;
        width: 50%;
        .qua-message-title {
          width: 50%;
          height: 40px;
          .sch-1 {
            width: 100%;
            min-width: 300px;
            display: flex;
            .sch-title {
              width: 20%;
              min-width: 80px;
              height: 40px;
              font-size: 13px;
              background-color: #f5f7fa;
              color: #909399;
              border: 1px solid #dcdfe6;
              border-right: none;
              border-radius: 4px 0px 0px 4px;
              display: flex;
              align-items: center;
              justify-content: center;
              padding: 0px 0px 0px 5px !important;
            }
          }
        }
        .Qualification-img {
          width: 50%;
        }
        .zw-vox-img {
          width: 100%;
          margin-right: 10px;
          // display: flex;
          .describe {
            div:nth-child(1) {
              font-weight: bold;
              font-size: 16px;
            }
            div:nth-child(2) {
              font-size: 14px;
              line-height: 20px;
            }
          }
        }
        .marbox {
          display: flex;
        }
        .img-box {
          // width: 100px;
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
          .upImg {
            width: 220px;
            height: 160px;
            margin-top: 10px;
            margin-left: 10px;
            .el-image {
              width: 220px;
              height: 160px;
            }
          }
          .describe {
            line-height: 20px;
            padding: 0px;
            width: 100%;
            height: 20px;
            font-size: 14px;
            text-align: center;
            font-weight: bold;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            cursor: pointer;
            color: #333;
          }
        }
      }
    }
  }
  .operating-btn {
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: center;
    margin-top: 50px;
    .btn-1 {
      width: 100px;
      height: 40px;
      background: #f5f6f7;
      color: #bbbdbf;
      border-radius: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      // font-weight: bold;
      cursor: pointer;
      // margin-left: 20px;
    }
    .btn-2 {
      background: #3d70ff;
      color: #fff;
    }
  }
}
.dis {
  width: 100%;
  display: flex;
  // justify-content: space-between;
  align-items: center;
}
::v-deep .el-form-item__content {
  margin-left: 30px !important;
}
</style>
